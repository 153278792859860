import type { Language } from "types";
import { addLineBreaks } from "utils";
import company from "translations/company.json";
import "./Address.css";

function Address({ lang, withDetails }: Props) {
  const { name, address: adr, nip, regon } = company[lang];
  const address = addLineBreaks(adr, "address");

  return (
    <>
      <span className="new-line address">{name}</span>
      {address}
      {withDetails && (
        <>
          <span className="new-line address address--separator">
            NIP: {nip}
          </span>
          <span className="new-line address">Regon: {regon}</span>
        </>
      )}
    </>
  );
}

type Props = {
  lang: Language;
  withDetails?: boolean;
};

export default Address;
