import { ChangeEvent, FormEvent, useState, useRef } from "react";
import type { Language } from "types";
import { form } from "utils";
import contactForm from "translations/contact-form.json";
import Button from "components/general/Button";
import "./ContactForm.css";

function ContactForm({ lang }: Props) {
  const contact = contactForm[lang];
  const [loaded, setLoaded] = useState(false);

  const [emailIsTouched, setEmailIsTouched] = useState(false);
  const [subjectIsTouched, setSubjectIsTouched] = useState(false);
  const [messageIsTouched, setMessageIsTouched] = useState(false);

  const [emailIsValid, setEmailIsValid] = useState(true);
  const [subjectIsValid, setSubjectIsValid] = useState(true);
  const [messageIsValid, setMessageIsValid] = useState(true);

  const [status, setStatus] = useState<"" | "success" | "fail" | "sending">("");

  const invalidClass = " form__label--invalid";
  const emailClass = emailIsValid ? "" : invalidClass;
  const subjectClass = subjectIsValid ? "" : invalidClass;
  const messageClass = messageIsValid ? "" : invalidClass;

  const emailRef = useRef<HTMLInputElement>(null);
  const subjectRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;

    if (emailIsTouched) {
      setEmailIsValid(form.validate.email(emailValue));
    }

    setStatus("");
  };

  const handleSubjectChange = (e: ChangeEvent<HTMLInputElement>) => {
    const subjectValue = e.target.value;

    if (subjectIsTouched) {
      setSubjectIsValid(form.validate.subject(subjectValue));
    }

    setStatus("");
  };

  const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const messageValue = e.target.value;

    if (messageIsTouched) {
      setMessageIsValid(form.validate.message(messageValue));
    }

    setStatus("");
  };

  const handleSuccess = () => {
    const form = document.querySelector(".form") as HTMLFormElement;
    if (form) form.reset();
    setStatus("success");
  };

  const handleFail = () => {
    setStatus("fail");
  };

  const handleSubmission = (event: FormEvent) => {
    event.preventDefault();

    const emailValue = emailRef.current?.value || "";
    const subjectValue = subjectRef.current?.value || "";
    const messageValue = messageRef.current?.value || "";

    const emailValidity = form.validate.email(emailValue);
    const subjectValidity = form.validate.subject(subjectValue);
    const messageValidity = form.validate.message(messageValue);

    setEmailIsValid(emailValidity);
    setSubjectIsValid(subjectValidity);
    setMessageIsValid(messageValidity);

    setEmailIsTouched(true);
    setSubjectIsTouched(true);
    setMessageIsTouched(true);

    if (!emailValidity || !subjectValidity || !messageValidity) {
      return;
    }

    setStatus("sending");

    form.send(
      "https://contact-api.sataku.com/api/carla-send",
      {
        email: emailValue,
        subject: subjectValue,
        message: messageValue,
      },
      handleSuccess,
      handleFail
    );
  };

  // Fix static html generator bugs with placeholders not being in their languages
  window.addEventListener("load", () => {
    setLoaded(true);
  });

  return (
    <form onSubmit={handleSubmission} className="form" noValidate>
      <label className={"form__label form__label--email" + emailClass}>
        <span className="form__label-row">
          <span className="form__label-left">{contact.emailLabel}</span>
          <span className="form__label-right">
            {!emailIsValid && contact.emailError}
          </span>
        </span>
        <input
          placeholder={loaded ? contact.emailPlaceholder : ""}
          className="form__input form__input--email"
          type="email"
          name="email"
          id="email"
          ref={emailRef}
          onChange={handleEmailChange}
        />
      </label>

      <label className={"form__label form__label--subject" + subjectClass}>
        <span className="form__label-row">
          <span className="form__label-left">{contact.subjectLabel}</span>
          <span className="form__label-right">
            {!subjectIsValid && contact.subjectError}
          </span>
        </span>
        <input
          placeholder={loaded ? contact.subjectPlaceholder : ""}
          className="form__input form__input--subject"
          type="text"
          name="subject"
          id="subject"
          ref={subjectRef}
          onChange={handleSubjectChange}
        />
      </label>

      <label className={"form__label form__label--message" + messageClass}>
        <span className="form__label-row">
          <span className="form__label-left">{contact.messageLabel}</span>
          <span className="form__label-right">
            {!messageIsValid && contact.messageError}
          </span>
        </span>
        <textarea
          className="form__textarea"
          name="message"
          id="message"
          ref={messageRef}
          onChange={handleMessageChange}
        />
      </label>

      <p className="form__disclaimer">
        <span className="form__data-policy">{contact.disclaimer}</span>
        <span className="form__data-policy">
          {contact.dataPolicy}{" "}
          <a
            href="mailto:milena.stoch@carlazuri.com"
            target="_blank"
            rel="noreferrer"
          >
            {contact.dataInspector}
          </a>
          .
        </span>
      </p>

      <div className="form__flex-row">
        <Button>{contact.submitLabel}</Button>

        {status === "success" && (
          <p className="form__status form__status--success">
            {contact.statusSuccess}
          </p>
        )}

        {status === "fail" && (
          <p className="form__status form__status--fail">
            {contact.statusFail}
          </p>
        )}

        {status === "sending" && (
          <p className="form__status form__status--sending">
            {contact.statusSending}
          </p>
        )}
      </div>
    </form>
  );
}

type Props = {
  lang: Language;
};

export default ContactForm;
