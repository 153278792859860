import type { Language } from "types";
import { cls } from "utils";
import general from "translations/general.json";
import companyData from "translations/company.json";

const year = new Date().getFullYear();

function Copyright({ lang, className }: Props) {
  const { name } = companyData[lang];
  const { copyright } = general[lang];
  const classes = "copyright" + cls(className);

  return (
    <p className={classes}>
      {year} © {name} {copyright}
    </p>
  );
}

type Props = {
  lang: Language;
  className?: string;
};

export default Copyright;
