import type { Language } from "types";
import sections from "translations/sections.json";
import socials from "translations/social-media.json";
import general from "translations/general.json";
import company from "translations/company.json";
import MenuSection from "./MenuSection";
import Address from "components/general/Address";
import Separator from "components/general/Separator";
import Copyright from "components/general/Copyright";
import "./MenuExpand.css";

function MenuExpand({
  lang,
  onMenuExpand,
  onSectionLinkClick,
  menuIsActive,
}: Props) {
  const sectionList = sections[lang];
  const socialMedia = socials[lang];
  const { addressLabel, writeLabel, closeLabel } = general[lang];

  type Links = { heading: string; slug: string }[];

  const socialMediaLinks: Links = socialMedia.map((item) => ({
    heading: item.name,
    slug: item.url,
  }));

  return (
    <nav className="menu-expand">
      <button
        className="menu-expand__button button-nav-hover"
        onClick={onMenuExpand}
        aria-labelledby="menu-button-label"
      >
        <span className="button-nav button-nav--light button-nav--icon">
          <span
            className={
              "button-nav-icon button-nav-icon--" +
              (menuIsActive ? "close" : "menu")
            }
          ></span>
        </span>
        <span className="menu-expand__button-label" id="menu-button-label">
          {menuIsActive ? closeLabel : "Menu"}
        </span>
      </button>

      <ul className="menu-expand__section-list">
        <div className="menu-expand__first-col">
          {sectionList.map((section, i) => {
            if (sectionList[i - 1]?.name === section.name) return "";
            let links: Links = [];
            links.push({ heading: section.heading, slug: "#" + section.slug });
            const nextSection = sectionList[i + 1];

            if (nextSection?.name === section.name) {
              links.push({
                heading: nextSection.heading,
                slug: "#" + nextSection.slug,
              });
            }

            return (
              <MenuSection
                key={i}
                name={section.name}
                links={links}
                onSectionLinkClick={() => onSectionLinkClick()}
                isMenuExpanded={menuIsActive}
              />
            );
          })}
        </div>

        <div className="menu-expand__second-col">
          <MenuSection
            name="Social Media"
            links={socialMediaLinks}
            newTab
            isMenuExpanded={menuIsActive}
          />
          <MenuSection
            name={writeLabel}
            links={[
              {
                slug: "mailto:" + company[lang].email,
                heading: company[lang].email,
              },
            ]}
            isMenuExpanded={menuIsActive}
          />

          <li className="menu-section menu-section--address">
            <span className="menu-section__name">{addressLabel}</span>

            <ul className="menu-section__heading-list">
              <li className="menu-section__heading">
                <Address lang={lang} />
              </li>
            </ul>
          </li>
        </div>
      </ul>

      <div className="menu-expand__bottom">
        <Separator className="menu-expand__separator" />
        <Copyright lang={lang} className="menu-expand__copyright" />
      </div>
    </nav>
  );
}

type Props = {
  lang: Language;
  menuIsActive: boolean;
  onMenuExpand: () => void;
  onSectionLinkClick: () => void;
};

export default MenuExpand;
