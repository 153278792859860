import type { Language } from "types";
import sections from "translations/sections.json";
import Heading from "components/general/Heading";
import Headline from "components/general/Headline";
import ContactPerson from "./ContactPerson";
import ContactForm from "./ContactForm";
import "./Contact.css";

function Contact({ lang }: Props) {
  const { heading, headline, slug } = sections[lang][6];

  return (
    <>
      <Heading slug={slug}>{heading}</Heading>
      <Headline className="contact__headline">{headline}</Headline>
      <ContactPerson lang={lang} />
      <ContactForm lang={lang} />
    </>
  );
}

type Props = {
  lang: Language;
};

export default Contact;
